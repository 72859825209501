<template>
  <v-list-item dense v-if="!isClicked">
    <v-list-item-avatar tile>
      <planete-icon width="24" height="24">{{ category.icon }}</planete-icon>
    </v-list-item-avatar>
    <v-list-item-content style="cursor: pointer"
        @click="goToNotification(notification)">
      <v-list-item-title
      >
        {{ $t("notifications." + category.slug + ".subtitle") }}
    </v-list-item-title>
    <v-list-item-subtitle>
        {{ notification.data.nom }} {{ notification.data.prenom }} -
        {{ category.subtitle }}
        <span class="">{{ notification.data.trigramme }}</span>
      </v-list-item-subtitle>
      
    </v-list-item-content>
  </v-list-item>
</template>

<script>

export default {
  name: "NouvelFiche",
  props: {
    notification: Object,
    category: Object
  },
  data() {
    return {
      cliked: false
    };
  },
  computed: {
    // si on clic une fois sur l item on le cache
    isClicked() {
      return this.cliked ? true : false;
    }
  },
  methods: { 
    async goToNotification(notification) {
    let host = this.getDns();
    let route = 'https://' + host + '/plannet/module/fiches_rh/api/red_new_agent.php';
    
    // Soit avec window.location
    // window.location.href = `${route}?id=${notification.data.fiche_id}`;

    // OU avec window.open
    window.open(`${route}?id=${notification.data.fiche_id}`, '_blank'); 
    this.cliked = true;
    
}
  }, 
}
</script>

<style scoped>

</style>